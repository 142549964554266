export default function getRelativeTime(time: Date): string {
    const now = new Date();
    const diff = Math.abs(time.getTime() - now.getTime());
    const mark = (time.getTime() - now.getTime()) >> -1 || 1;

    if (diff === 0) return new Intl.RelativeTimeFormat('en').format(0, "second");

    const times = [
        { type: 'second' as Intl.RelativeTimeFormatUnit, seconds: 1000 },
        { type: 'minute' as Intl.RelativeTimeFormatUnit, seconds: 60 * 1000 },
        { type: 'hour' as Intl.RelativeTimeFormatUnit, seconds: 60 * 60 * 1000 },
        { type: 'day' as Intl.RelativeTimeFormatUnit, seconds: 24 * 60 * 60 * 1000 },
        { type: 'week' as Intl.RelativeTimeFormatUnit, seconds: 7 * 24 * 60 * 60 * 1000 },
        { type: 'month' as Intl.RelativeTimeFormatUnit, seconds: 30 * 24 * 60 * 60 * 1000 },
        { type: 'year' as Intl.RelativeTimeFormatUnit, seconds: 12 * 30 * 24 * 60 * 60 * 1000 },
      ];

    let params: [number, Intl.RelativeTimeFormatUnit] = [0, 'seconds'];
    for (const t of times) {
        const segment = Math.round(diff / t.seconds);
        if (segment >= 0 && segment < 10) {
            params = [(segment * mark) | 0, t.type];
            break;
        }
    }
    return new Intl.RelativeTimeFormat('en').format(...params);
}
