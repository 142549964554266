import { Column, ColumnConfig } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { getAnalyticApplications, getJobsById } from "./api/ApplicationsChartAPI";
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const { MonthPicker } = DatePicker;

export const ApplicationsChart = () => {
  const [results, setResults] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<dayjs.Dayjs | null>(dayjs());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jobs = await getAnalyticApplications();
        setResults(jobs);

        if (selectedMonth) {
          const month = selectedMonth.month();
          const year = selectedMonth.year();

          const weeklyData: any = {
            "Week 1": { open: 0, draft: 0, closed: 0 },
            "Week 2": { open: 0, draft: 0, closed: 0 },
            "Week 3": { open: 0, draft: 0, closed: 0 },
            "Week 4": { open: 0, draft: 0, closed: 0 },
            "Week 5": { open: 0, draft: 0, closed: 0 },
          };

          const jobDetailsMap: any = {};

          const jobIds = Array.from(new Set(jobs.map((job: any) => job.jobId))) as string[];
          const jobDetailsPromises = jobIds.map((jobId: string) => getJobsById(jobId));

          const jobDetailsResults = await Promise.all(jobDetailsPromises);

          jobDetailsResults.forEach((jobDetail: any) => {
            jobDetail.candidates = jobs.filter((result: any) => result.jobId === jobDetail.id).map((result: any) => result.candidateId);
            jobDetailsMap[jobDetail.id] = jobDetail;
          });

          Object.values(jobDetailsMap).forEach((job: any) => {
            const createdAt = dayjs(job.createdAt);
            if (createdAt.month() === month && createdAt.year() === year) {
              const startOfMonth = dayjs(createdAt).startOf('month');
              const weekOfMonth = Math.ceil((createdAt.date() - startOfMonth.date() + 1) / 7);
              const weekKey = `Week ${weekOfMonth}`;

              if (weeklyData[weekKey]) {
                weeklyData[weekKey][job.status] += job.candidates.length;
              }
            }
          });

          const chartData = Object.keys(weeklyData).flatMap(week => 
            Object.keys(weeklyData[week])
              .filter(status => ["open", "draft", "closed"].includes(status))
              .map(status => ({
                week,
                type: convertStatus(status),
                value: weeklyData[week][status]
              }))
          );

          setData(chartData);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchData();
  }, [selectedMonth]);

  const handleMonthChange = (date: dayjs.Dayjs | null, dateString: string | string[]) => {
    setSelectedMonth(date);
  };

  const convertStatus = (status: string) => {
    switch (status) {
      case "open":
        return "active";
      case "draft":
        return "draft";
      case "closed":
        return "expired";
      default:
        return null;  // Tidak menampilkan status lain
    }
  };

  const config: ColumnConfig = {
    data: data,
    height: 300,
    xField: "week",
    yField: "value",
    seriesField: "type",
    isStack: true,
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
  };

  return (
    <div>
      <MonthPicker
        defaultValue={dayjs()}
        format="YYYY-MM"
        onChange={handleMonthChange}
        style={{ marginBottom: 16 }}
      />
      <Column {...config} />
    </div>
  );
};
