/* eslint-disable no-useless-catch */
import axios from "axios";

import { apiRequest, downloadFile } from "src/utils/API";
import { getToken } from "src/utils/AuthService";
import queryToString from "src/utils/queryToString";
import formatDateTime from "src/utils/formatDateTime";
import {
  IAntDProTableRequest,
  IAPIResponseGetJobApplication,
  IAPIResponseGetJobApplications,
  IJobApplication,
} from "../type/JobApplicationManagement";

const apiHost = process.env.REACT_APP_API_HOST;

export const getJobApplications = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  params.limit = params.pageSize;
  params.page = params.current;
  delete params.pageSize;
  delete params.current;
  const newParams = queryToString({ ...params, ...filter, ...sort });
  const response = await apiRequest<IAPIResponseGetJobApplications>(
    "GET",
    `/v1/admin/job-application/list?${newParams}`
  );

  return response.data;
};
export const getJobApplication = async (id: string) => {
  const response = await apiRequest<IAPIResponseGetJobApplication>(
    "GET",
    `/v1/admin/job-application/${id}`
  );
  return response.data;
};

export const createJobApplication = async (body: {
  data: IJobApplication;
  resume?: File;
  answers: any;
}) => {
  const formData = new FormData();
  formData.append("candidateId", body.data.candidateId);
  formData.append("jobId", body.data.jobId);
  if (body.answers) {
    formData.append("answers", JSON.stringify(body.answers));
  }
  try {
    const token = getToken();
    const response = await axios.post(
      `${apiHost}/v1/admin/job-application`,
      formData,
      {
        headers: {
          Authorization: token ? `Bearer ${token.access.token}` : "",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadJobApplicationList = async () => {
  const fileName = `Job_Application_List_${formatDateTime(new Date())}.csv`;
  await downloadFile("/v1/admin/job-application/list/download", fileName);
};

export const updateJobApplicationStatus = async (data: IJobApplication) => {
  return await apiRequest<IJobApplication>(
    "PATCH",
    "/v1/jobs/application/status",
    {
      id: data._id,
      newStatus: data.applicationStatus,
    }
  );
};
