/* eslint-disable no-useless-catch */
import { apiRequest, downloadFile } from "src/utils/API";
import queryToString from "src/utils/queryToString";
import {
  IAntDProTableRequest,
  IAPIResponseGetTalentSearchPackage,
  ITalentSearchPackage,
} from "../type/TalentSearchPackageManagement";
import formatDateTime from "src/utils/formatDateTime";

export const getTalentSearchPackages = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  params.limit = params.pageSize;
  params.page = params.current;
  delete params.pageSize;
  delete params.current;
  const newParams = queryToString({ ...params, ...filter, ...sort });
  const response = await apiRequest<IAPIResponseGetTalentSearchPackage>(
    "GET",
    `/v1/admin/package/talent-search/list?${newParams}`
  );

  return response.data;
};

export const getTalentSearchPackageById = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetTalentSearchPackage>(
    "GET",
    `/v1/admin/package/talent-search/${id}`
  );
  return response.data;
};

export const createTalentSearchPackage = async (data: ITalentSearchPackage) => {
  return await apiRequest<ITalentSearchPackage>(
    "POST",
    "/v1/admin/package/talent-search",
    data
  );
};

export const downloadTalentSearchPackageList = async () => {
  const fileName = `Talent_Search_Packages_${formatDateTime(new Date())}.csv`;
  await downloadFile("/v1/admin/package/talent-search/list/download", fileName);
};

export const updateTalentSearchPackage = async (data: ITalentSearchPackage) => {
  return await apiRequest<ITalentSearchPackage>(
    "PATCH",
    "/v1/admin/package/talent-search",
    data
  );
};
