import {
  Avatar,
  Button,
  Layout,
  Tooltip,
  Typography
} from "antd";
import React from "react";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import logo from "src/assets/images/logo.png";
import RouteList from "src/routes/RouteList";
import { deleteUserSession, getUser } from "src/utils/AuthService";
import DynamicBreadcrumb from "../breadcrumb/Breadcrumb";
import SidebarMenu from "./sidebarMenu/sidebarMenu";

const { Header, Sider, Content } = Layout;
const { Text, Link } = Typography;

type TDashboardLayout = {
  children: React.ReactNode;
};
const DashboardLayout = ({ children }: TDashboardLayout) => {
  const navigate = useNavigate();
  const user = getUser();

  return (
    <Layout>
      <Sider
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
        style={{ minHeight: "100vh", backgroundColor: "#17183B" }}
        width={240}
      >
        <Link
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderBottom: "2px solid #DBDBDB"
          }}
          href="/"
        >
          <img
            src={logo}
            alt="logo"
            width={140}
            height={62}
            style={{
              objectFit: "contain",
            }}
          />
        </Link>
        <SidebarMenu />
      </Sider>
      <Layout>
        <Header style={{ width: "100%", borderBottom: "2px solid #DBDBDB" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 8,
            }}
          >
            <DynamicBreadcrumb />
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: 20
            }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 10,
                  maxHeight: 45,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text strong>{user?.name}</Text>
                  <Text>{user?.role}</Text>
                </div>
                <Avatar
                  style={{ marginLeft: 10 }}
                  src={user?.photo}
                />
              </div>
              <Tooltip title="Logout">
                <Button
                  shape="circle"
                  icon={<MdLogout style={{ display: "block", margin: "auto" }} size={18} />}
                  style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#DBDBDB" }}
                  onClick={() => {
                    deleteUserSession();
                    navigate(0);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Header>
        <Content>{RouteList()}</Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
