import { PlusOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import useLocationOptions from "src/hooks/useLocationOption";
import { ITalentSearchPackage } from "../type/TalentSearchPackageManagement";
const { Option } = Select;

interface AddTalentSearchPackageFormProps {
  visible: boolean;
  onCreate: (values: ITalentSearchPackage) => void;
  onCancel: () => void;
}

const TalentSearchPackageAdd: React.FC<AddTalentSearchPackageFormProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [form] = Form.useForm();

  const handleStateChange = (value: string) => {
    setSelectedState(value);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      width={800}
      title="Add Talent Search Package"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter package name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Please enter description" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="price"
              label="Price (MYR)"
              rules={[{ required: true, message: "Please enter price" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="validityPeriodDays"
              label="Validity Period (days)"
              rules={[
                {
                  required: true,
                  message: "Please enter validaty period (days)",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="talentSearchCredit"
              label="Talent Search Credit"
              rules={[
                {
                  required: true,
                  message: "Please enter talent search credit",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TalentSearchPackageAdd;
