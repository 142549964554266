import { ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  downloadCandidateList,
  getCandidates,
} from "./api/CandidateManagementAPI";
import { ICandidate } from "./type/CandidateManagement";
import { MdDownload } from "react-icons/md";

const CandidateManagement = () => {
  const { Title } = Typography;
  const navigate = useNavigate();

  const waitTime = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const columns: ProColumns<ICandidate>[] = [
    {
      title: "Name",
      dataIndex: "candidateName",
      key: "candidateName",
      width: 200,
      render: (text, record) => (
        <a href={`/users/candidate/${record.id}`}>{record.candidateName}</a>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      valueType: "select",
      valueEnum: {
        male: { text: "Male", status: "Default" },
        female: { text: "Female", status: "Default" },
      },
    },
    {
      title: "Email",
      dataIndex: ["userId", "email"],
      key: "email",
    },
    {
      title: "Contact No",
      dataIndex: ["userId", "phone"],
      key: "phone",
    },
    {
      title: "City",
      dataIndex: ["locationCity"],
      key: "locationCity",
    },
    {
      title: "State",
      dataIndex: ["locationState"],
      key: "locationState",
    },
    {
      title: "Created At",
      dataIndex: ["createdAt"],
      key: "createdAt",
      valueType: "dateTime",
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Candidate Management
        </Title>
        <Button
          type="primary"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={downloadCandidateList}
        >
          <MdDownload /> &nbsp; Download CSV
        </Button>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getCandidates({ filter, sort, params });
            const user = data.results;

            return {
              data: user,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 0,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
      />
    </div>
  );
};

export default CandidateManagement;
