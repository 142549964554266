import { apiRequest } from "src/utils/API";
import { IAPIResponseGetApplicationsAnalytics, IAPIResponseGetJobs } from "../type/ApplicationsChart";


export const getAnalyticApplications = async () => {
  const response = await apiRequest<IAPIResponseGetApplicationsAnalytics>(
    "GET",
    `/v1/admin/applications/analytic`
  );

  return response.data;
};

export const getJobsById = async (id: string) => {
  const response = await apiRequest<IAPIResponseGetJobs>(
    "GET",
    `/v1/jobs/${id}`
  );

  return response.data;
};