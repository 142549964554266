import { ProColumns, ProTable } from "@ant-design/pro-components";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, message, Typography } from "antd";
import { useState } from "react";
import { MdAdd, MdDownload } from "react-icons/md";

import {
  createTalentSearchPackage,
  getTalentSearchPackages,
  downloadTalentSearchPackageList,
} from "./api/TalentSearchPackageManagementAPI";
import { ITalentSearchPackage } from "./type/TalentSearchPackageManagement";
import TalentSearchAdd from "./view/TalentSearchPackageAdd";

const TalentSearchManagement = () => {
  const { Title } = Typography;
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const createTalentSearchMutation = useMutation(createTalentSearchPackage, {
    onSuccess: (data) => {
      message.success("Talent Search Package created successfully!");
      window.location.reload();
      setVisible(false);
    },
    onError: (error: Error) => {
      message.error(
        `Failed to create talent search package : ${error.message}`
      );
    },
  });

  const handleCreate = (values: ITalentSearchPackage) => {
    createTalentSearchMutation.mutateAsync(values);
  };

  const waitTimePromise = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const waitTime = async (time: number = 100) => {
    await waitTimePromise(time);
  };

  const columns: ProColumns<ITalentSearchPackage>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <a href={"/package/talent-search/" + record.id}>{record.name}</a>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Price (MYR)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Talent Search Credit",
      dataIndex: "talentSearchCredit",
      key: "talentSearchCredit",
    },
    {
      title: "Validity Period (days)",
      dataIndex: "validityPeriodDays",
      key: "validityPeriodDays",
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <TalentSearchAdd
        visible={visible}
        onCreate={handleCreate}
        onCancel={handleCancel}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Talent Search Package Management
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={showModal}
          >
            <MdAdd /> &nbsp; Add Talent Search Package
          </Button>
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={downloadTalentSearchPackageList}
          >
            <MdDownload /> &nbsp; Download CSV
          </Button>
        </div>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getTalentSearchPackages({
              filter,
              sort,
              params,
            });
            const user = data.results;

            return {
              data: user,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 10,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
      />
    </div>
  );
};

export default TalentSearchManagement;
