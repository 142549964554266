import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export interface ScreeningQuestionsOption {
  id: string;
  questionText: string;
  responses: [{
    responseType: string;
    responseText: string;
    options?: string[];
  }];
}

interface DataReturn {
  screeningQuestionsOptions: ScreeningQuestionsOption[] | undefined;
  screeningQuestionsOptionsLoading: boolean;
}

export const getScreeningQuestions = async (jobId: string) => {
  const response = await apiRequest<ScreeningQuestionsOption[]>(
    "GET",
    `/v1/admin/job/${jobId}/screening-questions`
  );
  return response.data;
};

function useScreeningQuestionsOptionsByJobId(jobId?: string): DataReturn {
  const [screeningQuestionsOptions, setScreeningQuestionsOptions] =
    useState<ScreeningQuestionsOption[]>();

  const queryOption = useQuery<ScreeningQuestionsOption[]>({
    queryKey: ["screening-question", jobId],
    queryFn: () => getScreeningQuestions(jobId!),
    onSuccess: (data) => {
      setScreeningQuestionsOptions(data);
    },
    enabled: !!jobId,
  });

  return {
    screeningQuestionsOptions,
    screeningQuestionsOptionsLoading: queryOption.isLoading,
  };
}

export default useScreeningQuestionsOptionsByJobId;
