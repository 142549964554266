import { useState } from "react";
import { Button, Descriptions, Image, Row, Typography } from "antd";
import { MdAdd, MdEdit } from "react-icons/md";

import ScreeningQuestionAdd from "./ScreeningQuestionAdd";
import ScreeningQuestionEdit from "./ScreeningQuestionEdit";
import { IJob } from "../type/JobManagement";

const { Text } = Typography;

const TabContent = ({
  job,
  activeKey,
  refetchGetJob,
  initialData,
}: {
  job: any;
  activeKey: string;
  refetchGetJob: () => void;
  initialData: IJob;
}) => {
  const [editVisibleMap, setEditVisibleMap] = useState<Record<string, boolean>>(
    {}
  );
  const [addVisible, setAddVisible] = useState<boolean>(false);

  const handleShowEdit = (questionId: string) => {
    setEditVisibleMap((prev) => ({ ...prev, [questionId]: true }));
  };

  const handleEditCancel = (questionId: string) => {
    setEditVisibleMap((prev) => ({ ...prev, [questionId]: false }));
  };

  const handleAddCancel = () => {
    setAddVisible(false);
  };

  const handleShowAdd = () => {
    setAddVisible(true);
  };

  switch (activeKey) {
    case "screeningQuestions":
      return (
        <>
          <Descriptions bordered column={1} style={{ marginTop: 20 }}>
            {job.screeningQuestions.length ? (
              job.screeningQuestions.map((question: any, index: number) => (
                <Descriptions.Item
                  label={index + 1}
                  labelStyle={{ width: "50px" }}
                  key={index}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {question.questionText}
                    <Button
                      type="primary"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => handleShowEdit(question.id)}
                    >
                      <MdEdit /> &nbsp; Edit
                    </Button>
                  </div>
                  {editVisibleMap[question.id] && (
                    <ScreeningQuestionEdit
                      onCancel={() => handleEditCancel(question.id)}
                      refetchGetJob={refetchGetJob}
                      initialData={question}
                    />
                  )}
                </Descriptions.Item>
              ))
            ) : (
              <Text>No screening questions available</Text>
            )}
          </Descriptions>
          <ScreeningQuestionAdd
            visible={addVisible}
            onCancel={handleAddCancel}
            refetchGetJob={refetchGetJob}
            initialData={initialData}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={handleShowAdd}
            >
              <MdAdd /> &nbsp; Add New Screening Question
            </Button>
          </div>
        </>
      );
    case "skills":
      return (
        <Descriptions bordered column={1} style={{ marginTop: 20 }}>
          {job.skills.length ? (
            job.skills.map((skill: string, index: number) => (
              <Descriptions.Item
                label={index + 1}
                labelStyle={{ width: "50px" }}
                key={index}
              >
                {skill}
              </Descriptions.Item>
            ))
          ) : (
            <Text>No skills available</Text>
          )}
        </Descriptions>
      );
    case "specializations":
      return (
        <Descriptions bordered column={1} style={{ marginTop: 20 }}>
          {job.specializations.length ? (
            job.specializations.map((benefit: string, index: number) => (
              <Descriptions.Item
                label={index + 1}
                labelStyle={{ width: "50px" }}
                key={index}
              >
                {benefit}
              </Descriptions.Item>
            ))
          ) : (
            <Text>No specializations available</Text>
          )}
        </Descriptions>
      );
    default:
      return null;
  }
};

export default TabContent;
