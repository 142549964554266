import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Upload,
  Input,
  Switch,
} from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/es/upload";
import useScreeningQuestionOptionByJobId from "src/hooks/useScreeningQuestionOptionByJobId";
import { getCandidates } from "src/pages/candidateManagement/api/CandidateManagementAPI";
import { getAnalyticJobs } from "src/components/dashboard/charts/jobs/api/JobsChartAPI";
import { IJobApplication } from "../type/JobApplicationManagement";

const { Option } = Select;

interface IJobApplicationAddProps {
  visible: boolean;
  onSubmit: (data: { values: IJobApplication; answers: any; resume?: File }) => void;
  onCancel: () => void;
}

interface Answer {
  questionId: string;
  response: any;
}

const JobApplicationAdd: React.FC<IJobApplicationAddProps> = ({
  visible,
  onSubmit,
  onCancel,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [candidates, setCandidates] = useState<any[]>([]);
  const [jobs, setJobs] = useState<any[]>([]);
  const [selectedJobId, setSelectedJobId] = useState<string>("");
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [form] = Form.useForm();

  const { screeningQuestionsOptions } = useScreeningQuestionOptionByJobId(selectedJobId);

  const handleCandidateChange = (value: string) => {
    form.setFieldsValue({ candidateId: value });
  };

  const handleJobChange = (value: string) => {
    form.setFieldsValue({ jobId: value });
    setSelectedJobId(value);
  };

  const handleFileChange = ({ file }: UploadChangeParam) => {
    if (file.status === "done" || file.status === "uploading") {
      setFile(file.originFileObj as File);
    }
  };

  const handleAnswerChange = (questionId: string, response: any) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );
      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex] = { questionId, response };
        return updatedAnswers;
      }
      return [...prevAnswers, { questionId, response }];
    });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onSubmit({ values, answers, resume: file || undefined });
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setFile(null);
    setAnswers([]);
    onCancel();
  };

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const data = await getCandidates({ filter: {}, sort: {}, params: {} });
        setCandidates(data.results);
      } catch (error) {
        console.error("Error fetching candidates:", error);
        setCandidates([]);
      }
    };

    fetchCandidates();
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const data = await getAnalyticJobs();
        setJobs(data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setJobs([]);
      }
    };

    fetchJobs();
  }, []);

  return (
    <Modal
      open={visible}
      width={600}
      title="Create Job Application"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={24}>
            <Form.Item
              name="candidateId"
              label="Select Candidate"
              rules={[{ required: true, message: "Please select a candidate" }]}
            >
              <Select
                placeholder="Select Candidate"
                allowClear
                onChange={handleCandidateChange}
              >
                {candidates.map((candidate) => (
                  <Option key={candidate.id} value={candidate.id}>
                    {candidate.candidateName} - {candidate.userId?.email} - {candidate.userId?.phone}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={24}>
            <Form.Item
              name="jobId"
              label="Select Job"
              rules={[{ required: true, message: "Please select a job" }]}
            >
              <Select
                placeholder="Select Job"
                allowClear
                onChange={handleJobChange}
              >
                {jobs.map((job) => (
                  <Option key={job.id} value={job.id}>
                    {job.jobTitle} - {job.jobType} - {job.workMode} - {job.locationCity} - {job.locationState}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {screeningQuestionsOptions?.map((question) => (
          <Form.Item
            key={question.id}
            name={`question_${question.id}`}
            label={question.questionText}
            rules={[
              {
                required: true,
                message: `Please answer the question: ${question.questionText}`,
              },
            ]}
          >
            {question.responses[0].responseType === "text" && (
              <Input
                placeholder={question.responses[0].responseText}
                onChange={(e) => handleAnswerChange(question.id, e.target.value)}
              />
            )}
            {question.responses[0].responseType === "boolean" && (
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(checked) => handleAnswerChange(question.id, checked)}
              />
            )}
            {question.responses[0].responseType === "multiple-choice" && (
              <Select
                placeholder={question.responses[0].responseText}
                mode="multiple"
                onChange={(value) => handleAnswerChange(question.id, value)}
              >
                {question.responses[0].options?.map((option: string, index: number) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ))}

        <Form.Item name="resume" label="Upload Resume">
          <Upload
            name="resume"
            showUploadList={false}
            customRequest={() => {}}
            onChange={handleFileChange}
          >
            <Button icon={<CloudUploadOutlined />}>
              Click to {!file ? "Upload" : "Replace"} Resume
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default JobApplicationAdd;
