import { Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ITransaction } from "../type/TransactionManagement";
import useTransactionStatusOptions from "src/hooks/useTransactionStatusOption";
const { Option } = Select;

interface EditTransactionFormProps {
  visible: boolean;
  onCreate: (values: ITransaction) => void;
  onCancel: () => void;
  initialData?: ITransaction;
}

const TransactionEdit: React.FC<EditTransactionFormProps> = ({
  visible,
  onCreate,
  onCancel,
  initialData,
}) => {
  const { id } = useParams<{ id: string }>();
  const [selectedState, setSelectedState] = useState<string>("");
  const [form] = Form.useForm();

  const { transactionStatusOptions } = useTransactionStatusOptions();

  const handleStateChange = (value: string) => {
    setSelectedState(value);
    form.setFieldsValue({ locationCity: undefined });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        // form.resetFields();
        onCreate({
          ...values,
          id,
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    }
  }, [initialData, form]);

  return (
    <Modal
      open={visible}
      width={500}
      title="Edit Transaction"
      okText="Update"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={24}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={24}>
            <Form.Item
              name="status"
              label="Transaction Status"
              rules={[
                { required: true, message: "Please select transaction status" },
              ]}
            >
              <Select
                placeholder="Select Status"
                allowClear
                onChange={handleStateChange}
              >
                {transactionStatusOptions?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TransactionEdit;
