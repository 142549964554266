import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export interface ICompany {
  id: string;
  companyName: string;
  industryCategory: string;
  locationCity: string;
  locationState: string;
}

export interface CompanyOptions {
  results: ICompany[];
}
interface DataReturn {
  companyOptions: ICompany[];
  companyOptionsLoading: boolean;
}

export const getCompanyOption = async () => {
  const response = await apiRequest<CompanyOptions>(
    "GET",
    `/v1/admin/company/list`
  );
  return response.data;
};

function useCompanyOptions(): DataReturn {
  const [companyOptions, setCompanyOptions] = useState<ICompany[]>([]);

  const queryOption = useQuery<CompanyOptions>({
    queryKey: ["company-option"],
    queryFn: () => getCompanyOption(),
    onSuccess: (data) => {
      if (data?.results) {
        setCompanyOptions(data.results);
      }
    },
  });

  return {
    companyOptions,
    companyOptionsLoading: queryOption.isLoading,
  };
}

export default useCompanyOptions;
