import { ProColumns, ProTable } from "@ant-design/pro-components";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, message, Typography } from "antd";
import { useState } from "react";
import { MdAdd, MdDownload } from "react-icons/md";

import {
  createCompanyPackage,
  getCompanyPackages,
  downloadCompanyPackageList,
} from "./api/CompanyPackageManagementAPI";
import { ICompanyPackage } from "./type/CompanyPackageManagement";
import CompanyAdd from "./view/CompanyPackageAdd";

const CompanyManagement = () => {
  const { Title } = Typography;
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const createCompanyMutation = useMutation(createCompanyPackage, {
    onSuccess: (data) => {
      message.success("Company created successfully!");
      setVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to add company: ${error.message}`);
    },
  });

  const handleCreate = (values: ICompanyPackage) => {
    createCompanyMutation.mutateAsync(values);
  };

  const waitTimePromise = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const waitTime = async (time: number = 100) => {
    await waitTimePromise(time);
  };

  const columns: ProColumns<ICompanyPackage>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <a href={"/package/" + record.id}>{record.name}</a>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Price (MYR)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Job Slots",
      dataIndex: "jobSlots",
      key: "jobSlots",
    },
    {
      title: "Manual Matching",
      dataIndex: "manualMatching",
      key: "manualMatching",
    },
    {
      title: "Period Days",
      dataIndex: "periodDays",
      key: "periodDays",
    },
    {
      title: "Hightlight Ads",
      dataIndex: "highlightAds",
      key: "highlightAds",
      render: (text, record) => (
        <div>{record.highlightAds ? "true" : "false"}</div>
      ),
    },
    {
      title: "Is Popular",
      dataIndex: "isPopular",
      key: "isPopular",
      render: (text, record) => (
        <div>{record.isPopular ? "true" : "false"}</div>
      ),
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <CompanyAdd
        visible={visible}
        onCreate={handleCreate}
        onCancel={handleCancel}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Company Package Management
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={showModal}
          >
            <MdAdd /> &nbsp; Add Company Package
          </Button>
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={downloadCompanyPackageList}
          >
            <MdDownload /> &nbsp; Download CSV
          </Button>
        </div>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getCompanyPackages({ filter, sort, params });
            const user = data.results;

            return {
              data: user,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 10,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
      />
    </div>
  );
};

export default CompanyManagement;
