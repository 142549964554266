import { apiRequest } from "src/utils/API";
import { IAPIResponseGetJobsAnalytics } from "../type/JobsChart";


export const getAnalyticJobs = async () => {
  const response = await apiRequest<IAPIResponseGetJobsAnalytics>(
    "GET",
    `/v1/admin/jobs/analytic`
  );

  return response.data;
};