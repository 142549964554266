import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type JobStatusOptions = string[];

interface DataReturn {
  jobStatusOptions: JobStatusOptions | undefined;
  jobStatusOptionsLoading: boolean;
}

export const getJobStatusOption = async () => {
  const response = await apiRequest<JobStatusOptions>("GET", `/v1/options/job-status-types`);
  return response.data;
};

function useJobStatusOptions(): DataReturn {
  const [jobStatusOptions, setJobStatusOptions] = useState<JobStatusOptions>();

  const queryOption = useQuery<JobStatusOptions>({
    queryKey: ["jobStatus-option"],
    queryFn: () => getJobStatusOption(),
    onSuccess: (data) => {
      if (data) {
        setJobStatusOptions(data);
      }
    },
  });

  return {
    jobStatusOptions,
    jobStatusOptionsLoading: queryOption.isLoading,
  };
}

export default useJobStatusOptions;
