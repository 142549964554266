import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type TransactionStatusOptions = string[];

interface DataReturn {
  transactionStatusOptions: TransactionStatusOptions | undefined;
  transactionStatusOptionsLoading: boolean;
}

export const getTransactionStatusOption = async () => {
  const response = await apiRequest<TransactionStatusOptions>(
    "GET",
    `/v1/options/transaction-status`
  );
  return response.data;
};

function useTransactionStatusOptions(): DataReturn {
  const [transactionStatusOptions, setTransactionStatusOptions] =
    useState<TransactionStatusOptions>();

  const queryOption = useQuery<TransactionStatusOptions>({
    queryKey: ["transactionStatus-option"],
    queryFn: () => getTransactionStatusOption(),
    onSuccess: (data) => {
      if (data) {
        setTransactionStatusOptions(data);
      }
    },
  });

  return {
    transactionStatusOptions,
    transactionStatusOptionsLoading: queryOption.isLoading,
  };
}

export default useTransactionStatusOptions;
