import { PlusOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { UploadChangeParam } from "antd/es/upload";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import useLocationOptions from "src/hooks/useLocationOption";
import useBenefitOptions from "src/hooks/useBenefitOptions";
import { ICompany } from "../type/CompanyManagement";
const { Option } = Select;

interface AddCompanyFormProps {
  visible: boolean;
  onCreate: (values: ICompany, logo: any) => void;
  onCancel: () => void;
  initialData?: ICompany;
  isEdit?: boolean;
}

const CompanyAdd: React.FC<AddCompanyFormProps> = ({
  visible,
  onCreate,
  onCancel,
  initialData,
  isEdit = false,
}) => {
  const { id } = useParams<{ id: string }>();
  const [selectedState, setSelectedState] = useState<string>("");
  const [form] = Form.useForm();
  const [logo, setLogo] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [changeLogo, setChangeLogo] = useState<boolean>(false);
  const [selectedBenefits, setSelectedBenefits] = useState<
    { id: string; description: string }[]
  >([]);

  const { locationOptions } = useLocationOptions();
  const { benefitOptions } = useBenefitOptions();

  const handleStateChange = (value: string) => {
    form.setFieldsValue({ locationCity: undefined });
    setSelectedState(value);
  };

  const handleBenefitChange = (value: string[]) => {
    const uniqueBenefits = Array.from(new Set(value));

    const newSelectedBenefits = uniqueBenefits.map((benefitId) => {
      const existingBenefit = selectedBenefits.find(
        (benefit) => benefit.id === benefitId
      );
      if (existingBenefit) {
        return existingBenefit;
      }
      const benefitOption = benefitOptions?.find(
        (option) => option.id === benefitId
      );
      return { id: benefitId, description: benefitOption?.description || "" };
    });

    setSelectedBenefits(newSelectedBenefits);
  };

  const handleDescriptionChange = (id: string, newDescription: string) => {
    setSelectedBenefits((prevBenefits) =>
      prevBenefits.map((benefit) =>
        benefit.id === id
          ? { ...benefit, description: newDescription }
          : benefit
      )
    );
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (!isEdit) {
          form.resetFields();
        }
        onCreate(
          { ...values, benefits: selectedBenefits },
          changeLogo ? file : null
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setChangeLogo(false);
    setLogo(null);
    form.resetFields();
    onCancel();
  };

  const validatePassword = (_: any, value: string) => {
    if (!value || form.getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("The two passwords do not match!"));
  };
  const handleLogoChange = ({ file }: UploadChangeParam) => {
    setChangeLogo(true);
    setFile(file.originFileObj as any);
    if (file.status === "uploading") {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as Blob);
      reader.onload = () => setLogo(reader.result as string);
    }
  };
  useEffect(() => {
    if (initialData) {
      const data = {
        id: initialData.id,
        companyName: initialData.companyName,
        industryCategory: initialData.industryCategory,
        locationCity: initialData.locationCity,
        locationState: initialData.locationState,
        phone: initialData.userId.phone,
        email: initialData.userId.email,
        totalEmployees: initialData.totalEmployees,
        websiteUrl: initialData.websiteUrl,
        linkedinUrl: initialData.linkedinUrl,
        facebookUrl: initialData.facebookUrl,
        twitterUrl: initialData.twitterUrl,
        instagramUrl: initialData.instagramUrl,
        benefits: initialData.benefits,
        aboutCompany: initialData.aboutCompany,
        tagLine: initialData.tagLine,
        founded: initialData.founded,
        lifeAt: initialData.lifeAt,
        officeAddress: initialData.officeAddress,
        billingAddress: initialData.billingAddress,
      };
      form.setFieldsValue(data);
      setLogo(initialData.logo);
      setSelectedBenefits(
        initialData.benefits.map((benefit) => ({
          id: benefit.benefitId.id,
          title: benefit.benefitId.title,
          icon: benefit.benefitId.icon,
          description: benefit.description,
        }))
      );
    }
  }, [initialData, form]);

  return (
    <Modal
      open={visible}
      width={1200}
      title={isEdit ? "Edit Company" : "Add Company"}
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 24,
            gap: 12,
          }}
        >
          <Avatar
            src={logo}
            size={160}
            style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }}
          >
            {!logo && "Logo"}
          </Avatar>
          <ImgCrop>
            <Upload
              name="logo"
              showUploadList={false}
              onChange={handleLogoChange}
            >
              <Button icon={<PlusOutlined />}>
                Click to {!logo ? "Upload" : "Replace"}
              </Button>
            </Upload>
          </ImgCrop>
        </div>
        <Row gutter={16}>
          {id && (
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                name="id"
                label="Company ID"
                rules={[
                  { required: true, message: "Please input the company ID!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="companyName"
              label="Company Name"
              rules={[
                { required: true, message: "Please input the company name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input a valid email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: !initialData,
                  message: "Please input the password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                { required: true, message: "Please input the phone number!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="confirmPassword"
              label="Retype Password"
              dependencies={["password"]}
              rules={[
                {
                  required: !initialData,
                  message: "Please retype the password!",
                },
                { validator: validatePassword },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={16} md={16}>
            <Form.Item
              name="industryCategory"
              label="Industry Category"
              rules={[
                {
                  required: true,
                  message: "Please input the industry category!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={8}>
            <Form.Item name="totalEmployees" label="Total Employees">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="locationState" label="State">
              {locationOptions && (
                <Select
                  placeholder="Select State"
                  allowClear
                  showSearch
                  onChange={handleStateChange}
                >
                  {Object.keys(locationOptions).map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="locationCity"
              label="Location City"
            >
              {locationOptions && (
                <Select placeholder="Select City" allowClear showSearch>
                  {locationOptions[selectedState]?.map((city: any) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="websiteUrl" label="Website URL">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="linkedinUrl" label="LinkedIn URL">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="facebookUrl" label="Facebook URL">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="twitterUrl" label="Twitter URL">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="instagramUrl" label="Instagram URL">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item label="Benefits">
              <Select
                mode="multiple"
                placeholder="Select Benefits"
                allowClear
                showSearch
                optionLabelProp="label"
                value={selectedBenefits.map((benefit) => benefit.id)}
                onChange={handleBenefitChange}
              >
                {benefitOptions?.map((benefit) => (
                  <Option
                    key={benefit.id}
                    value={benefit.id}
                    label={benefit.title}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={benefit.icon}
                        alt={benefit.title}
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      {benefit.title}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {selectedBenefits.map((benefit) => (
              <Form.Item
                key={benefit.id}
                label={`Edit Description for ${
                  benefitOptions?.find((option) => option.id === benefit.id)
                    ?.title
                }`}
              >
                <Input
                  value={benefit.description}
                  onChange={(e) =>
                    handleDescriptionChange(benefit.id, e.target.value)
                  }
                />
              </Form.Item>
            ))}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="aboutCompany" label="About Company">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="tagLine" label="Tagline">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="founded" label="Founded">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="lifeAt" label="Life At">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="officeAddress" label="Office Address">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="billingAddress" label="Billing Address">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CompanyAdd;
