import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export interface ScreeningQuestionsOption {
  id: string;
  questionText: string;
  responses: {
    responseType: string;
    responseText: string;
  };
}
interface DataReturn {
  screeningQuestionsOptions: ScreeningQuestionsOption[] | undefined;
  screeningQuestionsOptionsLoading: boolean;
}

export const getScreeningQuestions = async () => {
  const response = await apiRequest<ScreeningQuestionsOption[]>(
    "GET",
    `/v1/admin/screening-question/list`
  );
  return response.data;
};

function useScreeningQuestionsOptions(): DataReturn {
  const [screeningQuestionsOptions, setScreeningQuestionsOptions] =
    useState<ScreeningQuestionsOption[]>();

  const queryOption = useQuery<ScreeningQuestionsOption[]>({
    queryKey: ["screening-question"],
    queryFn: () => getScreeningQuestions(),
    onSuccess: (data) => {
      if (data) {
        setScreeningQuestionsOptions(data);
      }
    },
  });

  return {
    screeningQuestionsOptions,
    screeningQuestionsOptionsLoading: queryOption.isLoading,
  };
}

export default useScreeningQuestionsOptions;
