import { apiRequest } from "src/utils/API";
import { IAPIResponseGetCandidateAnalytics } from "../type/CandidatesChart";


export const getAnalyticCandidate = async () => {
  const response = await apiRequest<IAPIResponseGetCandidateAnalytics>(
    "GET",
    `/v1/admin/candidate/analytic`
  );

  return response.data;
};