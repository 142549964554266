import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import React from "react";

import { ICompanyPackage } from "../type/CompanyPackageManagement";
const { Option } = Select;

interface AddCompanyPackageFormProps {
  visible: boolean;
  onCreate: (values: ICompanyPackage) => void;
  onCancel: () => void;
}

const CompanyPackageAdd: React.FC<AddCompanyPackageFormProps> = ({
  visible,
  onCreate,
  onCancel
}) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      width={800}
      title="Add Company Package"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter package name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Please enter description" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="price"
              label="Price"
              rules={[
                { 
                  required: true, 
                  message: "Please enter price",
                },
                {
                  type: 'number',
                  min: 1,
                  message: "Price must be at least 1",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="jobSlots"
              label="Job Slots"
              rules={[
                { 
                  required: true, 
                  message: "Please enter job slots",
                },
                {
                  type: 'number',
                  min: 1,
                  message: "Job Slots must be at least 1",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="periodDays"
              label="Period Days"
              rules={[
                { 
                  required: true, 
                  message: "Please enter period days",
                },
                {
                  type: 'number',
                  min: 1,
                  message: "Period Days must be at least 1",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="manualMatching"
              label="Manual Matching"
              rules={[
                { 
                  required: true, 
                  message: "Please input manual matching",
                },
                {
                  type: 'number',
                  min: 1,
                  message: "Manual Matching must be at least 1",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="highlightAds"
              label="Highlight Ads"
              rules={[
                { required: true, message: "Please select highlight ads" },
              ]}
            >
              <Select
                placeholder="Select One"
                allowClear
              >
                <Option value={false}>False</Option>
                <Option value={true}>True</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="isPopular"
              label="Is Popular ?"
              rules={[{ required: true, message: "Please select if popular" }]}
            >
              <Select
                placeholder="Select One"
                allowClear
              >
                <Option value={false}>False</Option>
                <Option value={true}>True</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CompanyPackageAdd;
