import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ITalentSearchPackage } from "../type/TalentSearchPackageManagement";

interface EditTalentSearchPackageFormProps {
  visible: boolean;
  onCreate: (values: ITalentSearchPackage) => void;
  onCancel: () => void;
  initialData: ITalentSearchPackage;
}

const TalentSearchPackageEdit: React.FC<EditTalentSearchPackageFormProps> = ({
  visible,
  onCreate,
  onCancel,
  initialData,
}) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate({
          ...values,
          id,
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    }
  }, [initialData, form]);

  return (
    <Modal
      open={visible}
      width={800}
      title="Edit Talent Search Package"
      okText="Update"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter package name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Please enter description" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="price"
              label="Price (MYR)"
              rules={[{ required: true, message: "Please enter price" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="validityPeriodDays"
              label="Validity Period (days)"
              rules={[
                {
                  required: true,
                  message: "Please enter validaty period (days)",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="talentSearchCredit"
              label="Talent Search Credit"
              rules={[
                {
                  required: true,
                  message: "Please enter talent search credit",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TalentSearchPackageEdit;
