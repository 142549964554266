import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type JobTypeOptions = string[];

interface DataReturn {
  jobTypeOptions: JobTypeOptions | undefined;
  jobTypeOptionsLoading: boolean;
}

export const getJobTypeOption = async () => {
  const response = await apiRequest<JobTypeOptions>("GET", `/v1/options/job-types`);
  return response.data;
};

function useJobTypeOptions(): DataReturn {
  const [jobTypeOptions, setJobTypeOptions] = useState<JobTypeOptions>();

  const queryOption = useQuery<JobTypeOptions>({
    queryKey: ["jobType-option"],
    queryFn: () => getJobTypeOption(),
    onSuccess: (data) => {
      if (data) {
        setJobTypeOptions(data);
      }
    },
  });

  return {
    jobTypeOptions,
    jobTypeOptionsLoading: queryOption.isLoading,
  };
}

export default useJobTypeOptions;
