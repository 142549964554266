import { Col, Descriptions, Image, Row, Typography, Button } from "antd";
import { DeleteFilled } from "@ant-design/icons";

import { ICompany } from "../type/CompanyManagement";

const { Text, Link } = Typography;

const TabContent = ({
  company,
  activeKey,
  onDeleteGallery,
}: {
  company: ICompany;
  activeKey: string;
  onDeleteGallery?: (galleryLink: string) => void;
}) => {
  const DETAIL_ITEMS = [
    { label: "Company Name", children: company.companyName },
    { label: "Email", children: company.userId.email },
    { label: "Phone", children: company.userId.phone },
    { label: "Industry", children: company.industryCategory },
    {
      label: "Location",
      children: `${company.locationCity}, ${company.locationState}`,
    },
    { label: "Office Address", children: `${company.officeAddress}` },
    { label: "Billing Address", children: `${company.billingAddress}` },
    { label: "Total Employees", children: company.totalEmployees.toString() },
    {
      label: "Website",
      children: (
        <Link href={company.websiteUrl} target="_blank">
          {company.websiteUrl}
        </Link>
      ),
    },
    {
      label: "LinkedIn",
      children: (
        <Link href={company.linkedinUrl} target="_blank">
          {company.linkedinUrl}
        </Link>
      ),
    },
    {
      label: "Facebook",
      children: (
        <Link href={company.facebookUrl} target="_blank">
          {company.facebookUrl}
        </Link>
      ),
    },
    {
      label: "Twitter",
      children: (
        <Link href={company.twitterUrl} target="_blank">
          {company.twitterUrl}
        </Link>
      ),
    },
    {
      label: "Instagram",
      children: (
        <Link href={company.instagramUrl} target="_blank">
          {company.instagramUrl}
        </Link>
      ),
    },
    { label: "About Company", children: company.aboutCompany },
    { label: "Tagline", children: company.tagLine },
    { label: "Founded", children: company.founded },
    { label: "Life at", children: company.lifeAt },
  ];

  const handleDelete = (galleryLink: string) => {
    if (onDeleteGallery) {
      onDeleteGallery(galleryLink);
    }
  };

  switch (activeKey) {
    case "details":
      return (
        <Descriptions bordered column={1} style={{ marginTop: 20 }}>
          {DETAIL_ITEMS.map((item, index) => (
            <Descriptions.Item label={item.label} key={index}>
              {item.children}
            </Descriptions.Item>
          ))}
        </Descriptions>
      );
    case "galleries":
      return (
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          {company.galleries?.length ? (
            company.galleries.map((gallery: string, index: number) => (
              <Col
                key={index}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                style={{ position: "relative" }}
              >
                <Image
                  src={gallery}
                  alt={`Gallery ${index + 1}`}
                  style={{ width: "100%", borderRadius: 8 }}
                />
                <Button
                  icon={<DeleteFilled />}
                  size="small"
                  style={{
                    color: "red",
                    position: "absolute",
                    top: 8,
                    right: 15,
                    zIndex: 1,
                  }}
                  onClick={() => handleDelete(gallery)}
                />
              </Col>
            ))
          ) : (
            <Text>No galleries available</Text>
          )}
        </Row>
      );
    case "benefits":
      return (
        <Descriptions bordered column={1} style={{ marginTop: 20 }}>
          {company.benefits?.length ? (
            company.benefits.map((benefit, index: number) => (
              <Descriptions.Item
                label={index + 1 + "."}
                key={index}
                labelStyle={{ width: "50px" }}
              >
                <img
                  src={benefit.benefitId.icon}
                  alt={benefit.benefitId.title}
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                <br />
                <span>{benefit.benefitId.title}</span>
                <br />
                Description: {benefit.description}
              </Descriptions.Item>
            ))
          ) : (
            <Text>No benefits available</Text>
          )}
        </Descriptions>
      );
    default:
      return null;
  }
};

export default TabContent;
