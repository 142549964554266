import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CandidateManagement from "./pages/candidateManagement/CandidateManagement";
import CandidateDetails from "./pages/candidateManagement/view/CandidateDetail";
import CompanyManagement from "./pages/companyManagement/CompanyManagement";
import JobManagement from "./pages/jobManagement/JobManagement";
import CompanyDetails from "./pages/companyManagement/view/CompanyDetail";
import CompanyPackageManagement from "./pages/companyPackageManagement/CompanyPackageManagement";
import CompanyPackageDetails from "./pages/companyPackageManagement/view/CompanyPackageDetail";
import TalentSearchPackageManagement from "./pages/talentSearchPackageManagement/TalentSearchPackageManagement";
import TalentSearchPackageDetails from "./pages/talentSearchPackageManagement/view/TalentSearchPackageDetail";
import Dashboard from "./pages/dashboard/DashboardPage";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import Login from "./pages/login/Login";
import RegisterPage from "./pages/register/Register";
import JobDetails from "./pages/jobManagement/view/JobDetail";
import JobApplicationManagement from "./pages/jobApplicationManagement/JobApplicationManagement";
import JobApplicationDetail from "./pages/jobApplicationManagement/view/JobApplicationDetail";
import TransactionManagement from "./pages/transactionManagement/TransactionManagement";
import TransactionDetail from "./pages/transactionManagement/view/TransactionDetail";
import { PrivateRoute, PublicRoute } from "./utils/RouteHandlers";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" Component={Login} />
            <Route path="/" Component={Login} />
            <Route path="/forgot-password" Component={ForgotPassword} />
            <Route path="/register" Component={RegisterPage} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users/candidate" element={<CandidateManagement />} />
            <Route path="/users/candidate/:id" element={<CandidateDetails />} />
            <Route path="/users/company" element={<CompanyManagement />} />
            <Route path="/users/company/:id" element={<CompanyDetails />} />
            <Route path="/job" element={<JobManagement />} />
            <Route path="/job/:id" element={<JobDetails />} />
            <Route
              path="/job-application"
              element={<JobApplicationManagement />}
            />
            <Route
              path="/job-application/:id"
              element={<JobApplicationDetail />}
            />
            <Route path="/package" element={<CompanyPackageManagement />} />
            <Route path="/package/:id" element={<CompanyPackageDetails />} />
            <Route path="/transaction" element={<TransactionManagement />} />
            <Route path="/transaction/:id" element={<TransactionDetail />} />
            <Route
              path="/package/talent-search"
              element={<TalentSearchPackageManagement />}
            />
            <Route
              path="/package/talent-search/:id"
              element={<TalentSearchPackageDetails />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
