import { apiRequest, downloadFile } from "src/utils/API";
import queryToString from "src/utils/queryToString";
import {
  IAntDProTableRequest,
  IAPIResponseGetCandidate,
} from "../type/CandidateManagement";
import formatDateTime from "src/utils/formatDateTime";

export const getCandidates = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  params.limit = params.pageSize;
  params.page = params.current;
  delete params.pageSize;
  delete params.current;
  const newParams = queryToString({ ...params, ...filter, ...sort });
  const response = await apiRequest<IAPIResponseGetCandidate>(
    "GET",
    `/v1/admin/candidate/list?${newParams}`
  );

  return response.data;
};
export const getCandidate = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetCandidate>(
    "GET",
    `/v1/admin/candidate/${id}`
  );
  return response.data;
};

export const downloadCandidateList = async () => {
  const fileName = `Candidate_List_${formatDateTime(new Date())}.csv`;
  await downloadFile("/v1/admin/candidate/list/download", fileName);
};
