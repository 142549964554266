import { ProColumns, ProTable } from "@ant-design/pro-components";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, message, Typography } from "antd";
import { useState } from "react";
import { MdAdd, MdDownload } from "react-icons/md";

import { createJob, downloadJobList, getJobs } from "./api/JobManagementAPI";
import { IJob } from "./type/JobManagement";
import JobAdd from "./view/JobAdd";
import getRelativeTime from "src/utils/getRelativeTime";

const JobManagement = () => {
  const { Title } = Typography;
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const createJobMutation = useMutation(createJob, {
    onSuccess: (data) => {
      message.success("Job created successfully!");
      setVisible(false);
      window.location.reload();
    },
    onError: (error: Error) => {
      message.error(`Failed to create job: ${error.message}`);
    },
  });

  const handleCreate = (values: IJob) => {
    createJobMutation.mutateAsync(values);
  };

  const waitTimePromise = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const waitTime = async (time: number = 100) => {
    await waitTimePromise(time);
  };

  const columns: ProColumns<IJob>[] = [
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: (text, record) => (
        <a href={`/job/${record.id}`}>{record.jobTitle}</a>
      ),
    },
    {
      title: "Company Name",
      dataIndex: ["company", "companyName"],
      key: "companyName",
    },
    {
      title: "Job Type",
      dataIndex: "jobType",
      key: "jobType",
    },
    {
      title: "Location City",
      dataIndex: "locationCity",
      key: "locationCity",
    },
    {
      title: "Location State",
      dataIndex: "locationState",
      key: "locationState",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      valueType: 'select',
      valueEnum: {
        open: { text: "Open", status: "Success" },
        paused: { text: "Paused", status: "Warning" },
        expired: { text: "Expired", status: "Error" },
        drafted: { text: "Drafted", status: "Default" },
      },
    },
    {
      title: "Expiration",
      dataIndex: "endAt",
      key: "endAt",
      search: false,
      sorter: true,
      render: (text, record) => {
        if(text === "-")
          return text;
        if(record.status === "expired" || record.status === "paused")
          return "-";
        const relativeTime = getRelativeTime(new Date(text as string));
        return relativeTime;
      },
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <JobAdd
        visible={visible}
        onSubmit={handleCreate}
        onCancel={handleCancel}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Job Management
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={showModal}
          >
            <MdAdd /> &nbsp; Add Job
          </Button>
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={downloadJobList}
          >
            <MdDownload /> &nbsp; Download CSV
          </Button>
        </div>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getJobs({ filter, sort, params });
            const jobs = data.results;

            return {
              data: jobs,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 10,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
      />
    </div>
  );
};

export default JobManagement;
