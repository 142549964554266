import { ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, Typography } from "antd";
import { MdDownload } from "react-icons/md";

import {
  getTransactions,
  downloadTransactionList,
} from "./api/TransactionManagementAPI";
import { ITransaction } from "./type/TransactionManagement";

const TransactionManagement = () => {
  const { Title } = Typography;

  const waitTimePromise = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const waitTime = async (time: number = 100) => {
    await waitTimePromise(time);
  };

  const columns: ProColumns<ITransaction>[] = [
    {
      title: "Company Name",
      dataIndex: ["company", "companyName"],
      key: "amount",
      render: (text, record) => (
        <a href={"/users/transaction/" + record.id}>
          {record.company.companyName}
        </a>
      ),
    },
    {
      title: "Package Name",
      dataIndex: ["package", "name"],
      key: "name",
    },
    {
      title: "Transaction Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Created at (MM/DD/YYYY)",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div>{new Date(record.createdAt).toLocaleString()}</div>
      ),
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Transaction Management
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={downloadTransactionList}
          >
            <MdDownload /> &nbsp; Download CSV
          </Button>
        </div>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getTransactions({ filter, sort, params });
            const user = data.results;

            return {
              data: user,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 10,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
      />
    </div>
  );
};

export default TransactionManagement;
