import { useMutation } from "@tanstack/react-query";
import { Form, Input, Modal, Select, message } from "antd";
import React, { useEffect } from "react";

import { updateScreeningQuestion } from "../api/JobManagementAPI";
import { IScreeningQuestion } from "../type/JobManagement";

const { Option } = Select;

interface ScreeningQuestionEditProps {
  onCancel: () => void;
  refetchGetJob: () => void;
  initialData: IScreeningQuestion;
}

const responseTypeOptions = ["text", "boolean", "multiple-choice"];
const questionTypeOptions = ["Experience", "Custom", "License/Certification"];
const multipleChoiceAnswerOptions = ["true", "false"];

const ScreeningQuestionEdit: React.FC<ScreeningQuestionEditProps> = ({
  onCancel,
  refetchGetJob,
  initialData,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialData) {
      const data = {
        questionText: initialData.questionText,
        questionType: initialData.questionType,
        responseType: initialData.responses[0].responseType,
        responseText: initialData.responses[0].responseText,
        idealAnswer: initialData.responses[0].idealAnswer,
        options: initialData.responses[0].options || [],
        allowMultipleAnswer:
          initialData.responses[0].allowMultipleAnswer === true
            ? "true"
            : "false",
      };
      console.log("data ", data);
      form.setFieldsValue(data);
    }
  }, [initialData, form]);

  const updateScreeningQuestionMutation = useMutation(updateScreeningQuestion, {
    onSuccess: () => {
      refetchGetJob();
      message.success("Screening question updated successfully!");
      handleCancel();
    },
    onError: (error: Error) => {
      message.error(`Failed to edit screening question : ${error.message}`);
    },
  });

  const handleUpdate = (values: any) => {
    if (!initialData.id) return;
    const data = {
      id: initialData.id,
      questionText: values.questionText,
      questionType: values.questionType,
      responses: [
        {
          responseType: values.responseType,
          responseText: values.responseText,
          idealAnswer: values.idealAnswer,
          options: values.options,
          allowMultipleAnswer:
            values.allowMultipleAnswer === "true" ? true : false,
        },
      ],
    };
    updateScreeningQuestionMutation.mutateAsync(data);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        handleUpdate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={true}
      width={600}
      title="Edit Screening Question"
      okText="Update"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ responseType: "text", questionType: "Experience" }}
      >
        <Form.Item
          name="questionText"
          label="Question text"
          rules={[
            {
              required: true,
              message: "Please input question text",
            },
          ]}
        >
          <Input placeholder="Enter question text" />
        </Form.Item>
        <Form.Item
          name="questionType"
          label="Select a question type"
          rules={[
            {
              required: true,
              message: "Please select a question type",
            },
          ]}
        >
          <Select placeholder="Select a question type">
            {questionTypeOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="responseType"
          label="Select a response type"
          rules={[
            {
              required: true,
              message: "Please select a response type",
            },
          ]}
        >
          <Select placeholder="Select a response type">
            {responseTypeOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="responseText"
          label="Response Text"
          rules={[
            {
              required: true,
              message: "Please input response text",
            },
          ]}
        >
          <Input placeholder="Enter response text" />
        </Form.Item>
        <Form.Item name="idealAnswer" label="Ideal Answer">
          <Input placeholder="Enter ideal answer" />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.responseType !== currentValues.responseType
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue("responseType") === "multiple-choice" ? (
              <>
                <Form.Item
                  name="options"
                  label="Options for Multiple Choice"
                  rules={[
                    {
                      required: true,
                      message: "Please input multiple-choice options",
                    },
                  ]}
                >
                  <Select
                    mode="tags"
                    placeholder="Select or add multiple choice options"
                    tokenSeparators={[","]}
                    style={{ width: "100%" }}
                  ></Select>
                </Form.Item>

                <Form.Item
                  name="allowMultipleAnswer"
                  label="Allow multiple answers"
                  rules={[
                    {
                      required: true,
                      message: "Please select one",
                    },
                  ]}
                >
                  <Select placeholder="Select one">
                    {multipleChoiceAnswerOptions.map((option) => (
                      <Option key={option} value={option} defaultValue="true">
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ScreeningQuestionEdit;
