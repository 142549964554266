import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type JobApplicationStatusOptions = string[];

interface DataReturn {
  jobApplicationStatusOptions: JobApplicationStatusOptions | undefined;
  jobApplicationStatusOptionsLoading: boolean;
}

export const getJobApplicationStatusOption = async () => {
  const response = await apiRequest<JobApplicationStatusOptions>(
    "GET",
    `/v1/options/application-status-types`
  );
  return response.data;
};

function useJobApplicationStatusOptions(): DataReturn {
  const [jobApplicationStatusOptions, setJobApplicationStatusOptions] =
    useState<JobApplicationStatusOptions>();

  const queryOption = useQuery<JobApplicationStatusOptions>({
    queryKey: ["jobApplicationStatus-option"],
    queryFn: () => getJobApplicationStatusOption(),
    onSuccess: (data) => {
      if (data) {
        setJobApplicationStatusOptions(data);
      }
    },
  });

  return {
    jobApplicationStatusOptions,
    jobApplicationStatusOptionsLoading: queryOption.isLoading,
  };
}

export default useJobApplicationStatusOptions;
