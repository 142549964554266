/* eslint-disable no-useless-catch */
import { apiRequest, downloadFile } from "src/utils/API";
import queryToString from "src/utils/queryToString";
import {
  IAntDProTableRequest,
  IAPIResponseGetCompanyPackage,
  ICompanyPackage,
} from "../type/CompanyPackageManagement";
import formatDateTime from "src/utils/formatDateTime";

export const getCompanyPackages = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  params.limit = params.pageSize;
  params.page = params.current;
  delete params.pageSize;
  delete params.current;
  const newParams = queryToString({ ...params, ...filter, ...sort });
  const response = await apiRequest<IAPIResponseGetCompanyPackage>(
    "GET",
    `/v1/admin/package/list?${newParams}`
  );

  return response.data;
};
export const getCompanyPackageById = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetCompanyPackage>(
    "GET",
    `/v1/admin/package/${id}`
  );
  return response.data;
};

export const createCompanyPackage = async (data: ICompanyPackage) => {
  return await apiRequest<ICompanyPackage>("POST", "/v1/admin/package", data);
};

export const downloadCompanyPackageList = async () => {
  const fileName = `Company_Packages_${formatDateTime(new Date())}.csv`;
  await downloadFile("/v1/admin/package/list/download", fileName);
};

export const updateCompanyPackage = async (data: ICompanyPackage) => {
  return await apiRequest<ICompanyPackage>("PATCH", "/v1/admin/package", data);
};
