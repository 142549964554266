import { Card, Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import { CandidatesChart } from "src/components/dashboard/charts/candidate/Charts";
import { CompanyChart } from "src/components/dashboard/charts/company/Charts";
import { JobsChart } from "src/components/dashboard/charts/jobs/Charts";
import { ApplicationsChart } from "src/components/dashboard/charts/application/Charts";


const Dashboard = () => {
  return (
    <Content style={{ padding: 16 }}>
      <Row gutter={5}>
        <Col xs={24} xl={12}>
          <Card title="Candidates Chart" size="small" style={{ margin: 5 }}>
            <CandidatesChart />
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title="Company Chart" size="small" style={{ margin: 5 }}>
            <CompanyChart />
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title="Jobs Chart" size="small" style={{ margin: 5 }}>
            <JobsChart />
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title="Application Chart" size="small" style={{ margin: 5 }}>
            <ApplicationsChart />
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default Dashboard;
