import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  Image,
  message,
  Row,
  Spin,
  Tabs,
  Typography,
  Upload,
  Tag,
} from "antd";
import { useState } from "react";
import { MdEdit, MdUploadFile } from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  getCompany,
  updateCompany,
  uploadCompanyGalleries,
  deleteGallery,
  uploadCompanyLogo,
  verifyCompany,
  unverifyCompany,
} from "../api/CompanyManagementAPI";
import { ICompany } from "../type/CompanyManagement";
import CompanyAdd from "./CompanyAdd";
import TabContent from "./TabContent";
import ConfirmationModal from "src/components/shared/confirmationModal";
import { CheckCircleOutlined, RollbackOutlined } from "@ant-design/icons";

const { Title, Link } = Typography;
const { TabPane } = Tabs;

const CompanyDetails = () => {
  const { id: companyId } = useParams<{ id: string }>();
  const [activeKey, setActiveKey] = useState("details");
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [galleryFiles, setGalleryFiles] = useState<any[]>([]);
  const [verifyConfirmationVisible, setVerifyConfirmationVisible] = useState<boolean>(false);
  const [unverifyConfirmationVisible, setUnverifyConfirmationVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    data: company,
    isLoading,
    isError,
    refetch: refetchCompanyData,
  } = useQuery(["companyData", companyId], () => getCompany(companyId), {
    enabled: !!companyId,
  });
  const updateCompanyMutation = useMutation(updateCompany, {
    onSuccess: (data) => {
      message.success("Company updated successfully!");
      refetchCompanyData();
      setEditVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to edit company: ${error.message}`);
    },
  });
  const deleteGalleryMutation = useMutation(deleteGallery, {
    onSuccess: (data) => {
      refetchCompanyData();
      message.success("Gallery image deleted successfully!");
      setEditVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to delete gallery image : ${error.message}`);
    },
  });

  const uploadLogoMutation = useMutation(uploadCompanyLogo, {
    onError: (error: Error) => {
      message.error(`Failed to upload logo: ${error.message}`);
    },
  });

  const uploadGalleriesMutation = useMutation(uploadCompanyGalleries, {
    onSuccess: (data) => {
      refetchCompanyData();
      message.success("Galleries upload successfully!");
      setEditVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to upload: ${error.message}`);
    },
  });

  const verifyMutation = useMutation(verifyCompany, {
    onSuccess: (data) => {
      message.success("Company verified successfully!");
      refetchCompanyData();
      setVerifyConfirmationVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to verify company: ${error.message}`);
    },
  });

  const unverifyMutation = useMutation(unverifyCompany, {
    onSuccess: (data) => {
      message.success("Company unverified successfully!");
      refetchCompanyData();
      setUnverifyConfirmationVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to unverify company: ${error.message}`);
    },
  });

  const handleGalleryUpload = async () => {
    if (galleryFiles.length > 0) {
      await uploadGalleriesMutation.mutateAsync({
        companyId: company.id,
        galleries: galleryFiles,
      });
      setGalleryFiles([]);
    } else {
      message.warning("Please select files to upload.");
    }
  };

  const handleGalleryDelete = async (galleryLink: string) => {
    if (!companyId) return;
    await deleteGalleryMutation.mutateAsync({
      companyId,
      galleryLink,
    });
  };

  const handleGalleryChange = ({
    file,
    fileList,
  }: {
    file: any;
    fileList: any;
  }) => {
    console.log(file);
    console.log(fileList);
    if (file.status !== "uploading") {
      setGalleryFiles([...galleryFiles, file]);
    }
  };

  const handleUpdate = async (values: ICompany, logo: File) => {
    const { data } = await updateCompanyMutation.mutateAsync({
      ...values,
      userId: company.userId.id,
    });
    if (data && logo) {
      await uploadLogoMutation.mutateAsync({ companyId: data.id, logo });
    }
  };

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const handleEdit = () => {
    setEditVisible(true);
  };

  const handleCancel = () => {
    setEditVisible(false);
  };

  const handleVerify = async () => {
    setLoading(true);
    await verifyMutation.mutateAsync({ companyId: company.id });
    setLoading(false);
  };

  const handleUnverify = async () => {
    setLoading(true);
    await unverifyMutation.mutateAsync({ companyId: company.id });
    setLoading(false);
  };

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  if (isError || !company) {
    return <div>Failed to load company details</div>;
  }

  const DESCRIPTION_ITEMS = [
    { label: "Company Name", children: company.companyName },
    { label: "Email", children: company.userId.email },
    { label: "Phone", children: company.userId.phone },
    { label: "Industry", children: company.industryCategory },
    { label: "Business Certificate", children: (company?.businessCertificate ? <Link href={company.businessCertificate} target="_blank">View Certificate</Link> : "-") },
    {
      label: "Location",
      children: `${company.locationCity}, ${company.locationState}`,
    },
    {
      label: "Company Verification", children: (
        company.verified ?
          <>
            <Tag color="green">Verified</Tag>
            <Button 
              danger
              onClick={() => setUnverifyConfirmationVisible(true)}
              style={{ marginLeft: 10 }}
              icon={<RollbackOutlined />}
              iconPosition="end"
            >
              Revoke
            </Button>
          </> :
          <>
            <Tag color="red">Not Verified</Tag>
            <Button
              onClick={() => setVerifyConfirmationVisible(true)}
              style={{ marginLeft: 10 }}
              icon={<CheckCircleOutlined />}
              iconPosition="end"
            >
              Verify
            </Button>
          </>
      )
    },
  ];

  return (
    <Card
      style={{
        borderRadius: 8,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        margin: "16px",
      }}
    >
      <ConfirmationModal
        visible={verifyConfirmationVisible}
        title="Verify Company"
        body={<p>Are you sure you want grant <b>{company.companyName}</b> the <b><i>Verified</i></b> status? This action will allow this company to use core features such as purchasing packages and post job ads.</p>}
        cancelActionText="Cancel"
        confirmActionText="Yes, Verify Company"
        onConfirm={handleVerify}
        onCancel={() => setVerifyConfirmationVisible(false)}
        loading={loading}
      />
      <ConfirmationModal
        visible={unverifyConfirmationVisible}
        title="Revoke Verified Status"
        body={<p>Are you sure you want to revoke verified status of <b>{company.companyName}</b> and change the status to <b><i>Not Verified</i></b>? This action will make this company unable to use the core features such as posting job ads and purchasing packages.</p>}
        cancelActionText="Cancel"
        confirmActionText="Yes, Revoke Verified Status"
        onConfirm={handleUnverify}
        onCancel={() => setUnverifyConfirmationVisible(false)}
        loading={loading}
      />
      <CompanyAdd
        isEdit={true}
        visible={editVisible}
        onCancel={handleCancel}
        onCreate={handleUpdate}
        initialData={company}
      />
      <Title level={2} style={{ marginBottom: "16px", textAlign: "center" }}>
        Company Details
      </Title>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={8}
          lg={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={company.logo}
            alt="company logo"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
          />
        </Col>
        <Col xs={24} sm={16} lg={20}>
          <Descriptions
            title="Company Info"
            bordered
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
            style={{ marginBottom: "16px" }}
          >
            {DESCRIPTION_ITEMS.map((item, index) => (
              <Descriptions.Item label={item.label} key={index}>
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={handleEdit}
            >
              <MdEdit /> &nbsp; Edit Company
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Upload
                multiple
                beforeUpload={() => false}
                onChange={handleGalleryChange}
                fileList={galleryFiles.map((file) => ({
                  uid: file.uid,
                  name: file.name,
                  status: "done",
                  url: URL.createObjectURL(file),
                }))}
              >
                <Button icon={<MdUploadFile />}>Select Galleries</Button>
              </Upload>
              <Button type="primary" onClick={handleGalleryUpload}>
                Upload Galleries
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorBorderSecondary: "none",
            },
          },
        }}
      >
        <div style={{ borderTop: "1px solid #ddd", marginTop: "20px" }} />
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={handleTabChange}
          style={{ marginTop: "20px" }}
        >
          <TabPane tab="Details" key="details">
            <TabContent company={company} activeKey={activeKey} />
          </TabPane>
          <TabPane tab="Galleries" key="galleries">
            <TabContent
              company={company}
              activeKey={activeKey}
              onDeleteGallery={handleGalleryDelete}
            />
          </TabPane>
          <TabPane tab="Benefits" key="benefits">
            <TabContent company={company} activeKey={activeKey} />
          </TabPane>
        </Tabs>
      </ConfigProvider>
    </Card>
  );
};

export default CompanyDetails;
