import { Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ICompanyPackage } from "../type/CompanyPackageManagement";
const { Option } = Select;

interface EditCompanyPackageFormProps {
  visible: boolean;
  onCreate: (values: ICompanyPackage) => void;
  onCancel: () => void;
  initialData?: ICompanyPackage;
}

const CompanyPackageEdit: React.FC<EditCompanyPackageFormProps> = ({
  visible,
  onCreate,
  onCancel,
  initialData,
}) => {
  const { id } = useParams<{ id: string }>();
  const [selectedState, setSelectedState] = useState<string>("");
  const [form] = Form.useForm();

  const handleStateChange = (value: string) => {
    setSelectedState(value);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate({
          ...values,
          id,
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    }
  }, [initialData, form]);

  return (
    <Modal
      open={visible}
      width={800}
      title="Edit Company Package"
      okText="Update"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter package name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Please enter description" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="price"
              label="Price"
              rules={[{ required: true, message: "Please enter price" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="jobSlots"
              label="Job Slots"
              rules={[{ required: true, message: "Please enter job slots" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="periodDays"
              label="Period Days"
              rules={[{ required: true, message: "Please enter period days" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="manualMatching"
              label="Manual Matching"
              rules={[
                { required: true, message: "Please input manual matching" },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="highlightAds"
              label="Hightlight Ads"
              rules={[
                { required: true, message: "Please select hightlight ads" },
              ]}
            >
              <Select
                placeholder="Select One"
                allowClear
                onChange={handleStateChange}
              >
                <Option value={false}>false</Option>
                <Option value={true}>true</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="isPopular"
              label="Is Popular ?"
              rules={[{ required: true, message: "Please select is popular" }]}
            >
              <Select
                placeholder="Select One"
                allowClear
                onChange={handleStateChange}
              >
                <Option value={false}>false</Option>
                <Option value={true}>true</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CompanyPackageEdit;
