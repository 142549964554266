import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type BenefitOptions = {
  id: string;
  icon: string;
  type: string;
	title: string;
  description: string;
}[];

interface DataReturn {
  benefitOptions: BenefitOptions | undefined;
  benefitOptionsLoading: boolean;
}

export const getBenefitOption = async () => {
  const response = await apiRequest<BenefitOptions>("GET", `/v1/benefits`);
  return response.data;
};

function useBenefitOptions(): DataReturn {
  const [benefitOptions, setBenefitOptions] = useState<BenefitOptions>();

  const queryOption = useQuery<BenefitOptions>({
    queryKey: ["benefit-option"],
    queryFn: () => getBenefitOption(),
    onSuccess: (data) => {
      if (data) {
        setBenefitOptions(data);
      }
    },
  });

  return {
    benefitOptions,
    benefitOptionsLoading: queryOption.isLoading,
  };
}

export default useBenefitOptions;
