import { apiRequest } from "src/utils/API";
import { IAPIResponseGetCompanyAnalytics } from "../type/CompanyChart";


export const getAnalyticCompany = async () => {
  const response = await apiRequest<IAPIResponseGetCompanyAnalytics>(
    "GET",
    `/v1/admin/company/analytic`
  );

  return response.data;
};